@use '@amzn/awsui-design-tokens/polaris.scss' as awsui;

.disabled-form-field {
  [class^='awsui_label'] {
    color: awsui.$color-text-form-default !important;
  }
}

.success-status-button {
  border-color: awsui.$color-border-status-success !important;
  [class^='awsui_icon'] {
    color: awsui.$color-border-status-success !important;
  }
  [class^='awsui_content'] {
    color: awsui.$color-border-status-success !important;
  }
}

.info-status-button {
  border-color: awsui.$color-border-status-info !important;
  [class^='awsui_icon'] {
    color: awsui.$color-border-status-info !important;
  }
  [class^='awsui_content'] {
    color: awsui.$color-border-status-info !important;
  }
}


.error-status-button {
  border-color: awsui.$color-border-status-error !important;
  [class^='awsui_icon'] {
    color: awsui.$color-border-status-error !important;
  }
  [class^='awsui_content'] {
    color: awsui.$color-border-status-error !important;
  }
}

.width-10px {
  width: 10px !important;
}

.width-8-rem {
  width: 8rem !important;
}

.width-10-rem {
  width: 10rem !important;
}

.width-12-rem {
  width: 12rem !important;
}

.width-15-rem {
  width: 15rem !important;
}

.width-20-rem {
  width: 20rem !important;
}

.width-25-rem {
  width: 25rem !important;
}

.width-30-rem {
  width: 30rem !important;
}

.width-35-rem {
  width: 35rem !important;
}

.width-40-rem {
  width: 40rem !important;
}

.width-45-rem {
  width: 45rem !important;
}

.width-50-rem {
  width: 50rem !important;
}

.width-55-rem {
  width: 55rem !important;
}

.max-width-70-per {
  max-width: 70% !important;
}

.max-width-80-per {
  max-width: 80% !important;
}

.horizontal-bar {
  position: relative;
  height: 1px;
  background-color: #E9EBED;

  &::before {
    content: '';
    position: absolute;
    top: 30%;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #E9EBED;
  }

  margin-bottom: 2px !important;
}