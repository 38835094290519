.xpt-app-layout-content {
  margin-bottom: 80px;
}

.onboarding-corp-segment {
  .onboarding-corp-segment-row {
    min-height: 7rem;
  }

  .required-column-width {
    width: 15rem;
  }

  .segment-column-width {
    width: 25rem;
  }

  .default-value-column-width {
    width: 30rem;
  }

  .allowed-value-column-width {
    width: 30rem;
  }
}

.onboarding-business-segment {
  .onboarding-business-segment-row {
    height: 7rem;
  }

  .id-column-width {
    width: 8rem;
  }

  .segment-column-width {
    width: 25rem;
  }

  .data-type-column-width {
    width: 30rem;
  }

  .remove-column-width {
    width: 30rem;
  }
}

.segment-hierarchy-modal {
  .modal-container {
    height: 5vh;
    width: 100%;
  }

  .modal-grid {
    height: 60vh;
    width: 100%;
  }

  .segment-hierarchy-modal-content {
    height: 65vh;
  }
}

.custom-modal {
  [class^='awsui_dialog'] {
    max-width: 1000px !important;
  }
}

.widget-container {
  padding: 0px;
}

.widget-content {
  overflow-y: auto;
  /* Enable scrolling for content */
}

.popover-content {
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.popover-content div {
  margin-bottom: 8px;
}

.transform {
  transform: scale(0.9);
  transform-origin: top left;
  width: 111.11%;
}

.announcement-box {
  height: 500px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.awsui_modal {
  &.awsui_large {
    height: 700px;
  }
}

.announcement-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.quill-editor {
  flex-grow: 1;
  height: 300px;
}

.announcement-widget-row {
  min-height: 5.6rem;

  .monthDate {
    height: 5rem !important;
    width: 5rem !important;
    // border: solid 1px red;
  }

  .data-classification-badges-widget {
    width: 25rem !important;

    .app-name-in-badge {
      cursor: pointer !important;
    }
  }
}
