@use '@amzn/awsui-design-tokens/polaris.scss' as awsui;
@import '@amzn/awsui-global-styles/polaris.css';

html {
  overflow: hidden;
  height: 100%;
}

body {
  margin: 0;
  overflow: auto;
  height: 100%;
}

/*
body {
  margin: 0;
  overflow: auto;
  height: 100%;
  transform: scale(0.9);
  transform-origin: top left;
  width: 111.11%;
  height: 111.11%;
}
*/