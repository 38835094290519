@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';
@import 'ag-grid-community/styles/ag-theme-quartz.css';
@import 'ag-grid-community/styles/ag-theme-balham.css';
@import 'ag-grid-community/styles/ag-theme-material.css';

// Variables
$font-size-header: 10px;
$font-size-row: 12px;
$padding-horizontal: 10px;
$background-color-header: #58606205; // light grey

$background-color-editable-cell: #ffffed; /* Light yellow color */
$background-color-editable-cell-dark: #eff3f74b; 
$background-color-read-only: #ebeae8;
$background-color-nan: lightcoral;
$font-color-nan: black;

.ag-header-cell,
.ag-header-group-cell {
  font-size: $font-size-header !important;
  font-weight: 600 !important;
  padding-left: $padding-horizontal !important;
  padding-right: $padding-horizontal !important;
  background-color: $background-color-header;
}

.ag-row {
  font-size: $font-size-row !important;
}

.ag-header-cell.text-center {
  .ag-header-cell-label {
    justify-content: center;
  }
}

.ag-icon-custom-stats {
  background: transparent
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBkPSJNNTEyIDI1NmMwIC45IDAgMS44IDAgMi43Yy0uNCAzNi41LTMzLjYgNjEuMy03MC4xIDYxLjNIMzQ0Yy0yNi41IDAtNDggMjEuNS00OCA0OGMwIDMuNCAuNCA2LjcgMSA5LjljMi4xIDEwLjIgNi41IDIwIDEwLjggMjkuOWM2LjEgMTMuOCAxMi4xIDI3LjUgMTIuMSA0MmMwIDMxLjgtMjEuNiA2MC43LTUzLjQgNjJjLTMuNSAuMS03IC4yLTEwLjYgLjJDMTE0LjYgNTEyIDAgMzk3LjQgMCAyNTZTMTE0LjYgMCAyNTYgMHM1MTIgMTE0LjYgNTEyIDI1NnptLTQwMCAzMmEzMiAzMiAwIDEgMC02NCAwIDMyIDMyIDAgMSAwIDY0IDB6bTAtOTZhMzIgMzIgMCAxIDAgMC02NCAzMiAzMiAwIDEgMCAwIDY0em0xNjAgOTZhMzIgMzIgMCAxIDAgLTY0IDAgMzIgMzIgMCAxIDAgNjQgMHptOTYgOTZhMzIgMzIgMCAxIDAgMC02NCAzMiAzMiAwIDEgMCAwIDY0eiIvPjwvc3ZnPg==')
    no-repeat center center;
  width: 16px;
  height: 16px;
  display: inline-block;
}

.editable-cell {
  background-color: $background-color-editable-cell;
}

.editable-cell-dark-theme {
  background-color: $background-color-editable-cell-dark;
}

.nan-cell {
  color: $font-color-nan;
  background-color: $background-color-nan;
}

.ag-grid-container {
  display: flex;
  height: calc(75vh - 50px);
}

.xpt-mapping-ag-grid-container {
  display: flex;
  height: calc(75vh - 10px);
}

.xpt-report-ag-grid-container {
  display: flex;
  height: calc(70vh - 50px);
}

.bold-text {
  font-weight: bold;
}

.variance-cell {
  font-weight: bold;
  border: 1px solid #e2e2e2;
}

.variance-percent-zero,
.variance-percent-null {
  font-weight: bold;
  border: 1px solid #e2e2e2;
}

.variance-percent-positive {
  background-color: #e6ffe6;
  font-weight: bold;
  border: 1px solid #e2e2e2;
}

.variance-percent-negative {
  background-color: #ffe6e6;
  font-weight: bold;
  border: 1px solid #e2e2e2;
}

.header-center .ag-header-cell-label {
  justify-content: center;
}

// Styling for variance headers
.header-variance {
  font-weight: bolder;
  padding: 5px;
  border-bottom: 2px solid #d1a000;
}

// Styling for variance percent headers
.header-variance-percent {
  font-weight: bolder;
  padding: 5px;
  border-bottom: 2px solid #6c6c6c;
}

// General styling for headers
.ag-header-cell {
  font-size: 0.9em;
  text-align: center;
  padding: 10px;
  border-right: 1px solid #e2e2e2;
}

.cell-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-header {
  display: flex;
  align-items: center;
}

.custom-ag-grid-header-info-icon {
  margin-left: 4px;
  cursor: pointer;
  font-size: 10px;
}

.required-cell {
  font-weight: bold;
}
